import React from "react"



class Tutorial extends React.Component {

  render() {
    return (
      <div className = "tutorial">
      <hr/>
        <p style={{marginBottom : 15, textAlign:"center"}} className="tutorial-pitch">
          La création du storyboard te permet de placer les différentes étapes clés de ton jeu.
          <br/>
          <strong>
            Utilise la carte et les types d'énigmes à droite pour commencer.
          </strong>
        </p>
        
        <p style={{display:"none"}}> Il est vivement conseillé que tu équilibres les énigmes de chaque type afin d'avoir la meilleure expérience de jeu possible  </p>
      </div>

    )
  }
}

export default Tutorial
