import React from "react"
import Layout from "../components/layout"
import Tutorial from "../components/tutorial"
import GoogleMapReact from 'google-map-react';
import Marker from '../components/markers'
import {connect} from "react-redux"
import {setSteps} from "../state/app"
import {setDistance} from "../state/app"
import { navigate } from "gatsby"
import Navbar from "../components/navbar"
import IosReorder from 'react-ionicons/lib/IosReorder'
import MdArrowDropdown from 'react-ionicons/lib/MdArrowDropdown'
import MdArrowDropup from 'react-ionicons/lib/MdArrowDropup'
import IosPin from 'react-ionicons/lib/IosPin'
import IosPinOutline from 'react-ionicons/lib/IosPinOutline'
import MdClose from 'react-ionicons/lib/MdClose'
import Reorder, {
  reorder,
  reorderImmutable,
  reorderFromTo,
  reorderFromToImmutable
} from 'react-reorder';
import distance from '../components/distance'

class StoryboardBuilder extends React.Component {

  state = {
    steps : [],
    buttons : [false,false,false,false],
    buttonIsSelected : false,
    currentButton : "",
    distance : 0,
    count : [{name : 'phone' , numb : 0},
             {name : 'statue' , numb : 0},
             {name : 'phoneXstatue', numb : 0}
           ] ,
    seePitch : true,
    reorder:true
   }

  googleMap = React.createRef();

  onReorder (event, previousIndex, nextIndex, fromId, toId) {

    this.setState({
      steps: reorder(this.state.steps, previousIndex, nextIndex),
    });

    this.calculateDistance()
  }

  componentWillMount() {
  }

  end() {
    this.saveSteps();
    navigate("/end");
  }

 calculateDistance() {
   console.log(this.state.steps)
    var prev = []
    let dist = 0
    this.state.steps.map((item,key) => {
      console.log(key)
        if (key>0) {
        dist = dist + distance(prev[0],prev[1],item.position.lat,item.position.lng,'K')
      }
        prev= [item.position.lat,item.position.lng]
        console.log(prev)
    }
    )
    dist = Math.round(dist*10)/10;
    this.setState({distance: dist})
 }

 changeText(key,e,prop){
   var steps = this.state.steps
   steps[key][prop] = e.target.value
   this.setState({steps})
 }

 typePlace(currentButton) {
   if (currentButton == 'position')
    return(<IosPinOutline className='type' fontSize='40px' color="white"></IosPinOutline>)
   else
    return(<img className='type' src={require("../images/"+currentButton+"_outline.svg")} />)
 }

  expendPannel(step, key) {
    var steps = this.state.steps
    const newExpendValue = ! step.expend;
    const newSteps = [...steps];

    newSteps.map((step) => {
      step.expend = false;
    })
    newSteps[key].expend = newExpendValue;

    if(newExpendValue) { // open
      this.setState({reorder:false});
    } else { // close
      this.setState({reorder:true});
    }

    this.setState({steps:newSteps})
  }

  deleteStep(step,key) {
    if(! window.confirm("Voulez-vous supprimer cette étape ?"))
      return;

    var steps = this.state.steps
    steps.splice(key,1)
    this.setState({steps})
  }

  saveSteps() {
    this.props.setSteps(this.state.steps);
  }

  addStep(title = "", place = [], comment = "", forcePosition, expend, lat, lng, currentButton) {
    let steps = [...this.state.steps];
    const newStep = {
      title,
      place,
      comment,
      expend,
      position : {lat, lng},
      currentButton,
    }

    steps.map((step) => {
      step.expend = false;
    })

    if(forcePosition) {
      steps.splice(forcePosition, 0, newStep);
    } else {
      if(steps.length === 0)
        steps = [newStep]
      else
        steps.push(newStep);
    }
    this.counter(currentButton)
    this.setState({steps},this.calculateDistance());
    setTimeout(() => {this.calculateDistance()}, 1000);
  }

  counter(currentButton) {
    this.state.count.map((count)=>{
      if (count.name == currentButton)
        count.numb ++
    })

  }

  updatevalue(id, type, newVal) {
    const newObject = {...this.state.steps[id]}
    newObject[type] = newVal;

    const newSteps = [...this.state.steps];
    newSteps[id] = newObject;
    this.setState({steps:newSteps})
  }

buttonState(id,button) {
  var buttons = this.state.buttons
  var trues = 0
  buttons.map((e)=> {if (e) trues++})
  if (trues < 1)
    {
      buttons[id]= !buttons[id]
      this.setCurrentButton(button)
      this.setState({buttonIsSelected : true})
    }
  else {
    if (buttons[id])
      {
    buttons[id] = !buttons[id]
    this.setState({buttonIsSelected : false})
    }
    else
      {
       var i  = buttons.indexOf(true)
       buttons[i] = !buttons[i]
       buttons[id] = !buttons[id]
       this.setCurrentButton(button)
      }
  }
  this.setState({buttons})
}

changeType(e,key) {
    var steps = this.state.steps
    steps[key].currentButton = e.target.value
    this.setState({steps})
}

setCurrentButton(button) {
  var currentButton = button
  this.setState({currentButton})
}

clickMap(lat,lng) {
  console.log(this.state.currentButton)
  if (this.state.buttonIsSelected)
  {
    this.addStep('Etape '+(this.state.steps.length),"","",false,true,lat,lng,this.state.currentButton )
  }
  else {
    this.calculateDistance()
  }
}

coordinates(step) {
  return('[' + step.position.lat + ';' + step.position.lng + ']')
}

  render() {
    let urlOption = {};
    if(!this.props.devMode)
      urlOption.key = process.env.GATSBY_MAPS_API_KEY;
    return (

      <Layout id="storyboard">
        <Navbar distance={this.state.distance} count={this.state.count}/>
        <div style={{ height:"90vh",marginTop : "10vh",overflowY:'scroll',position:'relative', paddingLeft:10, paddingRight:10}}>
          <div style = {{display : "none", marginTop : '10px',marginBottom : "10px", justifyContent:'flex-start', alignItems : 'center', position : 'relative'}} >
            <h3 style = {{margin : 0, }}>
              Construire les étapes
            </h3>

            <div style = {{display:'none',}} onMouseOver = {() => this.setState({seePitch : true})}  onMouseOut = {() => this.setState({seePitch : false})}>
              <div style = {{marginRight :5,fontSize:'10px',width:"15px", height : "15px",lineHeight:'15px',borderRadius:"20px",backgroundColor:'black',color : 'white', textAlign : 'center' }}>ℹ️</div>
              <p style = {{fontWeight:400,lineHeight:"15px"}}>Ton Pitch</p>
            </div>
          </div>

          <p className="pitch-container">
              <h4 style = {{fontWeight:600,lineHeight:"15px"}}>Ton Pitch</h4>
              {this.props.pitch}
          </p>

          <Reorder
            reorderId="liste"
            holdTime={200}
            onReorder={this.onReorder.bind(this)}
            disabled={!this.state.reorder} >
          {
            this.state.steps.map((step, key) => {
              return (
                <div style={{marginBottom:20, marginTop:20}}>
                  <div  className={(this.state.reorder)?"storyboard movable":"storyboard"}>
                    <IosReorder className="sb_icon" color="white" fontSize="40px" style={{display:"none", left:5,height:'100%', marginLeft:20}} />
                    <p style={{color:'white', fontSize:19, marginLeft:10}}>#{key}</p>
                    {this.typePlace(step.currentButton)}
                    <input onChange={(e) => this.changeText(key, e,'title')} value={step.title}/>
                    <button className="sb_icon sb_expendButton" style={{backgroundColor: "transparent", border:0, right:5,height:'100%'}} onClick={() => {this.expendPannel(step,key) }}>
                      { !step.expend ?
                        <MdArrowDropdown color="white" fontSize="40px"/>
                        : <MdArrowDropup color="white" fontSize="40px"/>
                      }
                    </button>
                  </div>
                    { step.expend  && <div className="sb_expendContainer">
                      <div style={{padding:15,position : "relative"}}>
                       <label>Type</label>
                      <select onChange={(e) => {this.changeType(e,key); this.setState({reorder:true});  console.log("enable order"); }} style={{marginBottom :20}} id="enigma" name="enigma" onFocus={() => {this.setState({reorder:false}); console.log("disable order")}} onBlur={() => {this.setState({reorder:true}); console.log("enable order")}}>
                        <option value="position"  selected={step.currentButton == 'position'}>Position
                        </option>
                        <option value="statue" selected={step.currentButton == 'statue'}>Interaction environnement

                        </option>
                        <option value="phone" selected={step.currentButton == 'phone'}>Interaction application

                        </option>
                        <option value="phoneXstatue" selected={step.currentButton == 'phoneXstatue'}>Interaction application & environnement
                        </option>
                      </select>
                      
                       <label>Notes</label>
                       <textarea onChange={(e) => this.changeText(key, e, 'comment')} value={step.comment} style={{marginBottom:15}}>
                       </textarea>
                       <div className="bottom-bar">
                        <button className = "validate btn"  onClick={() => this.expendPannel(step,key)}>
                          SAUVEGARDER
                        </button>
                        <button className = "delete btn" style = {{marginLeft : 10}} onClick={() => this.deleteStep (step,key)}>
                          SUPPRIMER
                        </button>
                        </div>
                      </div>
                     </div>
                    }
              </div>
              )
            })
          }
        </Reorder>
        { this.state.steps.length == 0 ?
          <Tutorial/>
          :
          <div style={{marginTop:10, textAlign:"center"}}>
            {/**<Link to="/storyboardBuilder"></Link>**/}
            <button onClick={() => this.end()} className="btn">
              TERMINER
            </button>
          </div>
        }
        </div>

        <div className={`map ${this.state.buttonIsSelected ? 'buttonSelected_'+ this.state.currentButton : ''}`} id="right-part" >
          <GoogleMapReact
            onClick={({lat, lng}) => this.clickMap(lat,lng)}
            ref={this.googleMap}
            defaultCenter={{ lat: 48.864716, lng: 48.864716 }}
            defaultZoom={4}
            //devMode={activeEnv === "development"}
            bootstrapURLKeys={urlOption}
            options={{
              disableDefaultUI:false,
              gestureHandling: 'greedy',
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: true,
              streetViewControl: false,
              rotateControl: true,
              fullscreenControl: false,
            }}
          >
          {this.state.steps.map((item,key) => {
              return (
                <Marker
                  offsetTop={-20}  offsetLeft={-10}
                  key={key}
                  lat={item.position.lat}
                  lng={item.position.lng}
                  color="#9AC4F8"
                  number={key}
                />
              );
          })}
              </GoogleMapReact>
          <div className = "toolbar">
            <button className={(this.state.buttons[0])?'tooltip active':'tooltip'} onClick={() => this.buttonState(0,'position')}  >
              { this.state.buttons[0]==true ?
              <IosPin fontSize='40px' color="#9AC4F8"></IosPin>
              :
              <IosPinOutline fontSize='40px' color="white"></IosPinOutline>
              }
              <span className = 'tooltiptext'>Point d'étape</span>
            </button>
            <button className={(this.state.buttons[1])?'tooltip active':'tooltip'} onClick={() => this.buttonState(1,'statue')}>
              { this.state.buttons[1]==true ?
              <img style={{width:'70%'}} src={require("../images/statue_fill_blue.svg")}/>
              :
              <img style={{width:'70%'}} src={require("../images/statue_fill.svg")} />
              }
              <span className = 'tooltiptext'>ÉNIGME : Interaction environnement</span>
            </button>

            <button  className={(this.state.buttons[2])?'tooltip active':'tooltip'} onClick={() => this.buttonState(2,'phone')}>
              { this.state.buttons[2]==true ?
              <img style={{width:'50%'}} src={require("../images/phone_fill_blue.svg")}/>
              :
              <img style={{width:'50%'}}src={require("../images/phone_fill.svg")} />
              }
              <span className = 'tooltiptext'>ÉNIGME : Interaction application</span>
            </button>
            <button  className={(this.state.buttons[3])?'tooltip active':'tooltip'} onClick={() => this.buttonState(3,'phoneXstatue')}>
              { this.state.buttons[3]==true ?
              <img style={{width:'80%'}} src={require("../images/phoneXstatue_fill_blue.svg")}/>
              :
              <img style={{width:'80%'}}src={require("../images/phoneXstatue_fill.svg")} />
              }
              <span className = 'tooltiptext'>ÉNIGME : Interaction application & environnement</span>
            </button>

          </div>
        </div>

      </Layout>
    )
  }
}

let activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

export default connect(
  state => {
    return {
      pitch : state.app.pitch
    }
  },

  dispatch => {
    return {
      setSteps : (content) => dispatch(setSteps(content)),
      setDistance : (content) => dispatch(setDistance(content))
    }
  },
)(StoryboardBuilder)
